import { useRef, useEffect } from 'react';

const useOutsideClick = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current &&
                !ref.current?.contains(event.target) &&
                !event.target.parentNode.classList.contains('sort_delete')) {
                callback();
            }
        };
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, callback]);
    return ref;
};

export { useOutsideClick as default };
