import { jsxs, jsx } from 'react/jsx-runtime';
import { ArrowExit20Regular } from '@fluentui/react-icons';
import { useTheme, Typography } from '@mui/material';
import { useState } from 'react';
import { Button } from '../button/Button.js';
import { CustomActionModal } from './CustomActionModal.js';

const DisposalRequestRemovalDialog = ({ title, open, onClose, onAction, }) => {
    useTheme();
    const [comment, setComment] = useState('');
    return (jsxs(CustomActionModal, { title: title, onClose: onClose, size: 'small', open: open, icon: jsx(ArrowExit20Regular, { style: { marginRight: '0.75rem' } }), actionButton: jsx(Button, { variant: 'contained', onClick: () => onAction(comment), children: "Remove" }), children: [jsx(Typography, { variant: 'body1', sx: { paddingBottom: '1rem' }, children: "Removing items from this Disposal Request will return their disposal status to Triggered, for the target Retention Class, so that they can be assigned to Disposal Requests in the future." }), jsx(Typography, { variant: 'body1', children: "Proceed?" })] }));
};

export { DisposalRequestRemovalDialog, DisposalRequestRemovalDialog as default };
