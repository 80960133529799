import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { Typography, Box } from '@mui/material';
import { Button } from './button/Button.js';
import { MoreHorizontal20Filled } from '@fluentui/react-icons';
import * as MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { palette } from '../themes/light.theme.js';
import { sizes } from '../themes/sizes.js';
import { Tooltip } from './Tooltip.js';

function BreadcrumbsWithMenu({ startIcon, crumbs = [], onClick, color, seperator = "/", lastCrumbColor = "#000", hideCurrentCrumb = true, }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (event) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const Spacer = () => (jsx(Button, { size: "small", disableRipple: true, sx: {
            background: "none",
            cursor: "default",
            padding: "0 !important",
            display: "inline-flex",
            minWidth: "unset",
            "& p": {
                width: "fit-content",
            },
            color: palette.purewhite,
            "&:hover": {
                background: "none",
            },
        }, children: jsx(Typography, { variant: "body2", color: color, children: seperator }) }));
    const conditionallyRenderSpacer = crumbs[1] != undefined && jsx(Spacer, {});
    const Item = ({ item, maxWidth, isFirst, color = palette.navy[900], }) => (jsx(Button, { size: "small", startIcon: startIcon && isFirst && startIcon, onClick: () => onClick(item), disableRipple: true, sx: {
            color: palette.purewhite,
            background: "none",
            padding: sizes.padding.small,
            minWidth: "0",
            maxWidth: `calc(${maxWidth})`,
            "&:hover": {
                boxShadow: "none",
                background: "none",
                textDecoration: "underline",
            },
        }, children: jsx(Typography, { sx: {
                whiteSpace: "nowrap",
                width: "100%",
                lineHeight: "26px",
                display: "flex"
            }, variant: "body1", color: color, children: jsx(Tooltip, { placement: "top", title: item?.name, children: jsxs(Box, { sx: {
                        "&:hover": {
                            textDecoration: "underline",
                        },
                        "& svg": {
                            color: color,
                        },
                    }, className: "encompaas-breadcrumb", style: {
                        display: "inline-flex",
                        alignItems: "center",
                        overflow: "hidden",
                        width: "100%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }, children: [item?.icon && jsx("div", { style: {
                                float: "left",
                                paddingRight: "0.25rem",
                                display: "flex"
                            }, children: item?.icon }), jsx("div", { style: {
                                width: "100%",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden"
                            }, children: item?.name })] }) }) }) }));
    const renderCrumbs = () => {
        switch (true) {
            //hide current crumb with a list of 5 or less crumbs
            case hideCurrentCrumb && crumbs.length > 1 && crumbs.length < 6:
                return (jsxs(Fragment, { children: [jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, "aria-labelledby": "breadcrumbs", slotProps: {
                                paper: {
                                    style: {
                                        height: "12rem",
                                    },
                                    sx: {
                                        "& ul": {
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                        },
                                    },
                                },
                            }, sx: {}, "data-testid": "encompaas-breadcrumbs-list", children: crumbs.slice(1, -4).map((x) => (jsxs(MenuItem, { onClick: () => onClick({
                                    id: x.id,
                                    itemId: x.id,
                                    name: x.name,
                                }), sx: {
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    flexDirection: "row",
                                    "& svg": {
                                        transform: "scale(1.3)",
                                    },
                                    "&:hover": {
                                        color: `${palette.navy[900]} !important`,
                                        fill: `${palette.navy[900]}`,
                                        "& svg": {
                                            color: `${palette.navy[900]}`,
                                        },
                                        borderRadius: "0 !important",
                                    },
                                }, children: [jsx("div", { style: { transform: `translateX(2rem)` }, children: x.icon }), x.name] }, x.id))) }), jsx(MUIBreadcrumbs.default, { sx: {
                                width: "100%",
                                "& li": {
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                },
                            }, "aria-label": "breadcrumbs", "data-testid": "encompaas-breadcrumbs", children: jsxs(Fragment, { children: [crumbs[1] != undefined && (jsx(Item, { item: crumbs.at(0), maxWidth: "20%", isFirst: true, color: color })), conditionallyRenderSpacer, crumbs.slice(2, 5).map((x, i) => {
                                        return (jsxs(React.Fragment, { children: [x != undefined && (jsx(Item, { item: crumbs[i + 1], maxWidth: `${17.5 - 2.5 * i}%`, color: color })), x != undefined && jsx(Spacer, {})] }, i));
                                    })] }) })] }));
            case !hideCurrentCrumb && crumbs.length > 1 && crumbs.length < 6:
                return (jsxs(Fragment, { children: [jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, "aria-labelledby": "breadcrumbs", slotProps: {
                                paper: {
                                    style: {
                                        height: "12rem",
                                    },
                                    sx: {
                                        "& ul": {
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                        },
                                    },
                                },
                            }, sx: {}, "data-testid": "encompaas-breadcrumbs-list", children: crumbs.slice(1, -4).map((x) => (jsxs(MenuItem, { onClick: () => onClick({
                                    id: x.id,
                                    itemId: x.id,
                                    name: x.name,
                                }), sx: {
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    flexDirection: "row",
                                    "& svg": {
                                        transform: "scale(1.3)",
                                    },
                                    "&:hover": {
                                        color: `${palette.navy[900]} !important`,
                                        fill: `${palette.navy[900]}`,
                                        "& svg": {
                                            color: `${palette.navy[900]}`,
                                        },
                                        borderRadius: "0 !important",
                                    },
                                }, children: [jsx("div", { style: { transform: `translateX(2rem)` }, children: x.icon }), x.name] }, x.id))) }), jsx(MUIBreadcrumbs.default, { sx: {
                                width: "100%",
                                "& li": {
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                },
                            }, "aria-label": "breadcrumbs", "data-testid": "encompaas-breadcrumbs", children: jsxs(Fragment, { children: [crumbs[1] != undefined && (jsx(Item, { item: crumbs.at(0), maxWidth: "20%", isFirst: true, color: color })), conditionallyRenderSpacer, crumbs.slice(1, 5).map((x, i, row) => {
                                        return (jsxs(React.Fragment, { children: [x != undefined && (jsx(Item, { item: crumbs[i + 1], maxWidth: `${17.5 - 2.5 * i}%`, color: color })), x != undefined &&
                                                    !hideCurrentCrumb &&
                                                    i + 1 === row.length ? (jsx(Fragment, {})) : (jsx(Spacer, {}))] }, i));
                                    })] }) })] }));
            //hide current crumb with a list of 6 or more crumbs
            case hideCurrentCrumb && crumbs.length > 1 && crumbs.length >= 6:
                return (jsxs(Fragment, { children: [jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, "aria-labelledby": "breadcrumbs", slotProps: {
                                paper: {
                                    style: {
                                        height: "12rem",
                                    },
                                    sx: {
                                        "& ul": {
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                        },
                                    },
                                },
                            }, "data-testid": "encompaas-breadcrumbs-list", children: crumbs.slice(1, -4).map((x) => (jsxs(MenuItem, { onClick: () => onClick({
                                    id: x.id,
                                    itemId: x.id,
                                    name: x.name,
                                }), sx: {
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    flexDirection: "row",
                                    "& svg": {
                                        transform: "scale(1.3)",
                                    },
                                    "&:hover": {
                                        color: `${palette.navy[900]} !important`,
                                        fill: `${palette.navy[900]}`,
                                        "& svg": {
                                            color: `${palette.navy[900]}`,
                                        },
                                        borderRadius: "0 !important",
                                    },
                                }, children: [x.icon, x.name] }, x.id))) }), jsx(MUIBreadcrumbs.default, { sx: {
                                width: "100%",
                                "& li": {
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                },
                            }, "aria-label": "breadcrumbs", "data-testid": "encompaas-breadcrumbs", children: jsxs(Fragment, { children: [jsx(Item, { item: crumbs.at(0), maxWidth: "20%", isFirst: true, color: color }), conditionallyRenderSpacer, jsx(Button, { size: "small", disableRipple: true, onClick: handleClick, sx: {
                                            background: "none",
                                            textOverflow: "ellipsis",
                                            padding: sizes.padding.small,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            maxWidth: "calc(17.5%)",
                                            cursor: "default",
                                            color: palette.aqua[800],
                                            paddingX: "0.25rem",
                                            height: "1.625rem",
                                            marginY: "auto",
                                        }, "data-testid": "encompaas-breadcrumbs-expand", children: jsx(MoreHorizontal20Filled, {}) }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-4), maxWidth: "15%", color: color }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-3), maxWidth: "12.5%", color: color }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-2), maxWidth: "10%", color: color }), conditionallyRenderSpacer] }) })] }));
            case !hideCurrentCrumb && crumbs.length > 1 && crumbs.length >= 6:
                return (jsxs(Fragment, { children: [jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, "aria-labelledby": "breadcrumbs", slotProps: {
                                paper: {
                                    style: {
                                        height: "12rem",
                                    },
                                    sx: {
                                        "& ul": {
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                        },
                                    },
                                },
                            }, "data-testid": "encompaas-breadcrumbs-list", children: crumbs.slice(1, -3).map((x) => (jsxs(MenuItem, { onClick: () => onClick({
                                    id: x.id,
                                    itemId: x.id,
                                    name: x.name,
                                }), sx: {
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    flexDirection: "row",
                                    "& svg": {
                                        transform: "scale(1.3)",
                                    },
                                    "&:hover": {
                                        color: `${palette.navy[900]} !important`,
                                        fill: `${palette.navy[900]}`,
                                        "& svg": {
                                            color: `${palette.navy[900]}`,
                                        },
                                        borderRadius: "0 !important",
                                    },
                                }, children: [x.icon, x.name] }, x.id))) }), jsx(MUIBreadcrumbs.default, { sx: {
                                width: "100%",
                                "& li": {
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                },
                            }, "aria-label": "breadcrumbs", "data-testid": "encompaas-breadcrumbs", children: jsxs(Fragment, { children: [jsx(Item, { item: crumbs.at(0), maxWidth: "20%", isFirst: true, color: color }), conditionallyRenderSpacer, jsx(Button, { size: "small", disableRipple: true, onClick: handleClick, sx: {
                                            background: "none",
                                            textOverflow: "ellipsis",
                                            padding: sizes.padding.small,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            maxWidth: "calc(17.5%)",
                                            cursor: "default",
                                            color: palette.aqua[800],
                                            paddingX: "0.25rem",
                                            height: "1.625rem",
                                            marginY: "auto",
                                        }, "data-testid": "encompaas-breadcrumbs-expand", children: jsx(MoreHorizontal20Filled, {}) }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-3), maxWidth: "15%", color: color }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-2), maxWidth: "12.5%", color: color }), conditionallyRenderSpacer, jsx(Item, { item: crumbs.at(-1), maxWidth: "10%", color: lastCrumbColor })] }) })] }));
            default:
                return conditionallyRenderSpacer;
        }
    };
    return renderCrumbs();
}

export { BreadcrumbsWithMenu as default };
