import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Button } from './button/Button.js';
import { PeopleTeam20Regular, DismissSquare20Regular, CheckmarkSquare20Regular, PeopleCheckmark20Regular, Delete20Regular, ArrowClockwise20Regular } from '@fluentui/react-icons';
import '@mui/material';
import SvgCloseRequest20 from '../icons/CloseRequest20.js';
import { Box } from './Box.js';
import { DisposalRequestResponseStatus, DisposalRequestStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';

const DisposalRequestHeaderActionButtons = ({ status, multipleRetentionClasses = false, onApprove, onReject, onDelegate, onChangeApprovers, onCloseRequest, onRequestApproval, onDestroyItems, onReapprove, onRetry, }) => {
    return (jsxs(Box, { background: 'none', justifyContent: 'end', direction: 'row', alignItems: 'center', gap: 0.625, shrink: true, children: [status === DisposalRequestResponseStatus.Pending && (jsxs(Fragment, { children: [jsx(Button, { startIcon: jsx(PeopleTeam20Regular, {}), variant: 'text', onClick: onDelegate, size: 'large', children: "Delegate" }), jsx(Button, { startIcon: jsx(DismissSquare20Regular, {}), variant: 'outlined', onClick: onReject, size: 'large', children: "Reject" }), jsx(Button, { startIcon: jsx(CheckmarkSquare20Regular, {}), onClick: onApprove, size: 'large', children: "Approve" })] })), [
                DisposalRequestStatus.New,
                DisposalRequestStatus.InApproval,
                DisposalRequestStatus.Approved,
                DisposalRequestStatus.Rejected,
            ].includes(status) && (jsx(Button, { startIcon: jsx(SvgCloseRequest20, {}), variant: 'outlined', onClick: onCloseRequest, size: 'large', children: "Close Request" })), status === DisposalRequestStatus.New && (jsx(Button, { startIcon: jsx(PeopleCheckmark20Regular, {}), onClick: onRequestApproval, size: 'large', children: "Request Approval" })), status === DisposalRequestStatus.InApproval && (jsx(Button, { startIcon: jsx(PeopleCheckmark20Regular, {}), onClick: onChangeApprovers, size: 'large', children: "Change Approvers" })), status === DisposalRequestStatus.Approved && (jsx(Button, { startIcon: jsx(Delete20Regular, {}), onClick: onDestroyItems, disabled: multipleRetentionClasses, size: 'large', children: "Destroy Items" })), status === DisposalRequestStatus.Rejected && (jsx(Button, { startIcon: jsx(PeopleCheckmark20Regular, {}), onClick: onReapprove, size: 'large', children: "Reapprove" })), status === DisposalRequestStatus.InProgress && (jsx(Button, { startIcon: jsx(ArrowClockwise20Regular, {}), onClick: onRetry, children: "Retry" }))] }));
};

export { DisposalRequestHeaderActionButtons };
