import {
  Box,
  ItemNavigationBar,
  ItemPanel,
} from '@encompaas/common/components';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';

const ItemPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('summary');

  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'summary';
    if (_selectedTab !== selectedTab) {
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const requestId = queryParams.get('requestId') ?? undefined;
  const requestName = queryParams.get('requestName') ?? undefined;

  const handleSelectTab = (tab: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('tab', tab);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    window.history.replaceState({}, '', newUrl);
  };

  const handleHome = () => navigate('/');

  const handleParentNavigation = () => {
    if (!requestId) return;
    navigate(`/request/${requestId}?tab=items`);
  };

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.ITEM_PAGE}>
      <Box background='light' rounded='top' blur style={{ overflow: 'auto' }}>
        <ItemNavigationBar
          parentName={requestName}
          onHome={handleHome}
          onParent={requestId ? handleParentNavigation : undefined}
        />
        <Divider style={{ width: '100%' }} />
        <ItemPanel
          id={id}
          initialTab={selectedTab}
          onSelectTab={handleSelectTab}
          adminMode={true}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default ItemPage;
