import { jsx } from 'react/jsx-runtime';
import { Copy20Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { Tooltip } from './Tooltip.js';

const CopyToClipboardButton = ({ text, copyMessage = 'Click to copy', copiedMessage = 'Copied', copyErrorMessage = 'Copy is not supported', color, }) => {
    const [tooltipTitle, setTooltipTitle] = useState(copyMessage);
    const handleClick = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setTooltipTitle(copiedMessage);
        }
        catch (err) {
            setTooltipTitle(copyErrorMessage);
        }
    };
    const handleMouseLeave = () => {
        setTooltipTitle(copyMessage);
    };
    return (jsx(Tooltip, { title: tooltipTitle, onOpen: handleMouseLeave, children: jsx(IconButton, { onClick: handleClick, size: 'small', disableRipple: true, sx: {
                color: { color },
            }, children: jsx(Copy20Regular, {}) }) }));
};

export { CopyToClipboardButton };
