import { useAlert } from './useAlert.js';

const useApiAlert = () => {
    const errorAlert = useAlert(false);
    const successAlert = useAlert(false);
    const retryAlert = useAlert(false);
    const setError = (title, message) => {
        errorAlert.setTitle(title);
        errorAlert.setMessage(message);
        errorAlert.openAlert();
    };
    const setSuccess = (title, message) => {
        successAlert.setTitle(title);
        successAlert.setMessage(message);
        successAlert.openAlert();
    };
    const setRetry = (title, message) => {
        retryAlert.setTitle(title);
        retryAlert.setMessage(message);
        retryAlert.openAlert();
    };
    return {
        errorAlert: {
            ...errorAlert,
            setError,
        },
        successAlert: {
            ...successAlert,
            setSuccess,
        },
        retryAlert: {
            ...retryAlert,
            setRetry
        }
    };
};

export { useApiAlert };
