import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from '@mui/system';
import { useTheme, Typography } from '@mui/material';

const FilterMenuItem = ({ value }) => {
    const theme = useTheme();
    return (jsxs(Box, { children: [jsx(Typography, { display: 'inline', variant: 'body3', color: theme.palette.info.dark, sx: { lineHeight: 'unset' }, children: value.value + ' ' }), jsxs(Typography, { display: 'inline', variant: 'body3', color: theme.palette.info.main, sx: { lineHeight: 'unset' }, children: ["(", value.count ?? 0, ")"] })] }));
};

export { FilterMenuItem as default };
