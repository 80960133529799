import { jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { MultilineTypography } from '../MultilineTypography.js';
import { TabPanel } from '../TabPanel.js';
import { DetailDialog } from './DetailDialog.js';

const DetailTabDialog = ({ tabs = [], ...props }) => {
    const [_tabs, setTabs] = useState([]);
    useEffect(() => {
        setTabs(tabs.map((tab) => ({
            ...tab,
            children: typeof tab?.children === 'string' ? (jsx(MultilineTypography, { variant: 'body1', mt: '1.875rem', children: tab?.children })) : (tab?.children),
        })));
    }, [tabs]);
    return (jsx(DetailDialog, { ...props, children: (tabs?.length ?? 0) === 1 ? (tabs[0].children) : (jsx(TabPanel, { tabs: _tabs, divider: false })) }));
};

export { DetailTabDialog, DetailTabDialog as default };
