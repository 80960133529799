import { jsx } from 'react/jsx-runtime';
import { Button } from '../../button/Button.js';
import '@fluentui/react-icons';
import '@mui/material';

const ActionButton = ({ onAction, name, comment, selectedApprovers, setIsValidCondition, setValidity, label }) => {
    const handleClick = () => {
        if (setIsValidCondition(selectedApprovers)) {
            onAction(name, comment, selectedApprovers);
        }
        else {
            setValidity(false);
        }
    };
    return (jsx(Button, { variant: 'contained', onClick: handleClick, children: label }));
};

export { ActionButton };
