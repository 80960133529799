import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FilterMenuItem from './components/FilterMenuItem.js';
import { ChevronDown20Filled } from '@fluentui/react-icons';
import useStyles from './styles.js';

const Filter = ({ selectedState, setSelectedState, defaultValue, data, labelId, id, }) => {
    const handleChange = (event) => {
        setSelectedState(event.target.value);
    };
    const theme = useTheme();
    const { styles } = useStyles();
    return (jsxs(FormControl, { sx: { width: 300 }, children: [jsx(InputLabel, { shrink: false, sx: styles.select, disableAnimation: true, id: labelId }), jsxs(Select, { labelId: labelId, id: id, value: selectedState, onChange: handleChange, defaultValue: defaultValue.value, IconComponent: () => (jsx(ChevronDown20Filled, { color: theme.palette.primary.main, style: {
                        paddingRight: '1rem',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '0',
                        pointerEvents: 'none',
                    } })), fullWidth: true, sx: {
                    ...styles.select,
                }, MenuProps: {
                    sx: {
                        marginTop: '0.5rem',
                    },
                    MenuListProps: {
                        sx: { boxShadow: 1 },
                    },
                }, children: [jsx(MenuItem, { sx: styles.menuItem, value: defaultValue.value, children: jsx(FilterMenuItem, { value: defaultValue }) }), data.map((x, i) => (jsx(MenuItem, { sx: styles.menuItem, value: x.value, children: jsx(FilterMenuItem, { value: x }) }, i)))] })] }));
};

export { Filter };
