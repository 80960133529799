import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { Box } from '../Box.js';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import { Person16Filled, ArrowMove20Regular } from '@fluentui/react-icons';
import { DeleteButton } from '../button/CustomButton.js';
import { DisposalRequestResponseStatusText } from '../DisposalRequestResponseStatusText.js';

const SortableItem = ({ id, name, index, onDelete, approver, isDisposalRequestRejected = false, notDraggable = false }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const [isHovered, setIsHovered] = useState(false);
    const disableMouseEvents = !isDisposalRequestRejected && (approver?.Status === 'Approved' || approver?.Status === 'Rejected' || approver?.Status === 'Delegated');
    const handleMouseEnter = () => {
        if (disableMouseEvents || notDraggable)
            return;
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        if (disableMouseEvents || notDraggable)
            return;
        setIsHovered(false);
    };
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const handleOnDelete = (_index) => {
        onDelete(_index);
    };
    const disabledStyle = disableMouseEvents ? { pointerEvents: 'none' } : {};
    const nonDraggableStyle = notDraggable ? {
        '&:hover': {
            cursor: 'default !important',
            background: 'transparent !important'
        },
        '&:active': {
            cursor: 'default !important',
            background: 'transparent !important'
        },
        '.MuiTouchRipple-child': {
            backgroundColor: 'transparent !important',
        },
    } : {};
    return (jsx(ListItem, { disablePadding: true, ref: setNodeRef, style: style, ...attributes, ...listeners, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: jsxs(ListItemButton, { sx: { ...disabledStyle, ...nonDraggableStyle }, children: [jsxs(Box, { direction: 'row', background: 'none', children: [jsx(Typography, { variant: 'body2', mr: 1, children: (index + 1).toString().padStart(2, '0') }), jsx(Person16Filled, {}), jsx(Typography, { variant: 'body2', ml: 1, children: name })] }), jsx(Box, { direction: 'row', background: 'none', justifyContent: 'end', children: !disableMouseEvents ? (jsxs(Fragment, { children: [jsx(ArrowMove20Regular, { style: {
                                    visibility: isHovered ? 'visible' : 'hidden',
                                    alignSelf: 'center',
                                } }), jsx(DeleteButton, { onClick: () => handleOnDelete(id), size: 'medium', className: 'sort_delete' })] })) : (jsx("div", { style: { height: '2.5rem', alignItems: 'center', display: 'flex' }, children: jsx(DisposalRequestResponseStatusText, { status: approver?.Status }) })) })] }) }));
};

export { SortableItem };
