import { jsx } from 'react/jsx-runtime';
import { CheckmarkSquare20Regular, DismissSquare20Regular, Clock20Regular, PeopleSwap20Regular, ArrowClockwiseDashes20Regular } from '@fluentui/react-icons';
import { DisposalRequestResponseStatus, DisposalRequestResponseStatusCaption } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { Chip } from './Chip.js';

const DisposalRequestResponseStatusChip = ({ status, border = false, ...props }) => {
    // can make these global if needed
    const _icon = {
        [DisposalRequestResponseStatus.Approved]: jsx(CheckmarkSquare20Regular, {}),
        [DisposalRequestResponseStatus.Rejected]: jsx(DismissSquare20Regular, {}),
        [DisposalRequestResponseStatus.Pending]: jsx(Clock20Regular, {}),
        [DisposalRequestResponseStatus.Delegated]: jsx(PeopleSwap20Regular, {}),
        [DisposalRequestResponseStatus.Waiting]: jsx(ArrowClockwiseDashes20Regular, {}),
    };
    const _color = {
        [DisposalRequestResponseStatus.Approved]: 'primary',
        [DisposalRequestResponseStatus.Rejected]: 'warning',
        [DisposalRequestResponseStatus.Pending]: 'default',
        [DisposalRequestResponseStatus.Delegated]: 'default',
        [DisposalRequestResponseStatus.Waiting]: 'default',
    };
    return (jsx(Chip, { ...props, border: border, title: DisposalRequestResponseStatusCaption[status], icon: _icon[status], color: _color[status], sx: {
            '& > .MuiChip-icon': {
                margin: '0 0.25rem 0 0',
                minWidth: '1.25rem',
            },
        } }));
};

export { DisposalRequestResponseStatusChip };
