import { jsx } from 'react/jsx-runtime';
import { Alert } from './Alert.js';

const DisposalRequestSuccessAlert = ({ open = false, onClose, title = 'Disposal Request Approved', message = 'Your response was successfully submitted.', }) => {
    return (jsx(Alert, { alertType: 'success', title: title, message: message, open: open, onClose: onClose, autoHideDuration: 10000 }));
};
const DisposalRequestWarningAlert = ({ open = false, title = 'Response submission failure', message = 'Your response submission failed. Please try again, or contact your Administrator.', onClose, }) => {
    return (jsx(Alert, { alertType: 'warning', title: title, message: message, open: open, onClose: onClose }));
};

export { DisposalRequestSuccessAlert, DisposalRequestWarningAlert };
