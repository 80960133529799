import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { DisposalRequestStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { DisposalRequestProgressIndicatorStatus, DisposalRequestProgressStatus } from './DisposalRequestProgressIndicatorStatus.js';

const getProgressStatus = (requestStatus, isBlocked, withWarning, multipleRetentionClasses) => {
    switch (requestStatus) {
        case DisposalRequestStatus.InApproval:
            return {
                indicator1: DisposalRequestProgressStatus.COMPLETE,
                indicator2: DisposalRequestProgressStatus.IN_PROGRESS,
                indicator3: DisposalRequestProgressStatus.PENDING,
                // indicator4: DisposalRequestProgressStatus.PENDING,
            };
        case DisposalRequestStatus.Approved:
            return {
                indicator1: DisposalRequestProgressStatus.COMPLETE,
                indicator2: DisposalRequestProgressStatus.COMPLETE,
                indicator3: isBlocked
                    ? DisposalRequestProgressStatus.BLOCKED
                    : DisposalRequestProgressStatus.PENDING,
                // indicator4: DisposalRequestProgressStatus.PENDING,
            };
        case DisposalRequestStatus.InProgress:
        case DisposalRequestStatus.Failed:
            return {
                indicator1: DisposalRequestProgressStatus.COMPLETE,
                indicator2: DisposalRequestProgressStatus.COMPLETE,
                indicator3: DisposalRequestProgressStatus.IN_PROGRESS,
                // indicator4: DisposalRequestProgressStatus.PENDING,
            };
        case DisposalRequestStatus.Complete:
            return {
                indicator1: DisposalRequestProgressStatus.COMPLETE,
                indicator2: DisposalRequestProgressStatus.COMPLETE,
                indicator3: DisposalRequestProgressStatus.COMPLETE,
                // indicator4: DisposalRequestProgressStatus.COMPLETE,
            };
        case DisposalRequestStatus.Rejected:
            return {
                indicator1: DisposalRequestProgressStatus.COMPLETE,
                indicator2: DisposalRequestProgressStatus.REJECTED,
                indicator3: DisposalRequestProgressStatus.PENDING,
                // indicator4: DisposalRequestProgressStatus.PENDING,
            };
        default:
            return {
                indicator1: multipleRetentionClasses
                    ? DisposalRequestProgressStatus.COMPLETE
                    : DisposalRequestProgressStatus.IN_PROGRESS,
                indicator2: DisposalRequestProgressStatus.PENDING,
                indicator3: DisposalRequestProgressStatus.PENDING,
                // indicator4: DisposalRequestProgressStatus.PENDING,
            };
    }
};
const DisposalRequestProgressIndicator = ({ requestStatus, withWarning, isBlocked, multipleRetentionClasses, ...rest }) => {
    if (![
        DisposalRequestStatus.New,
        DisposalRequestStatus.InApproval,
        DisposalRequestStatus.Approved,
        DisposalRequestStatus.Rejected,
        DisposalRequestStatus.Complete,
        DisposalRequestStatus.InProgress,
        DisposalRequestStatus.Failed,
    ].includes(requestStatus))
        return null;
    const { indicator1, indicator2, indicator3 } = getProgressStatus(requestStatus, isBlocked, withWarning, multipleRetentionClasses);
    return (jsxs(Box, { gap: 'medium', direction: 'row', background: 'none', ...rest, children: [jsx(DisposalRequestProgressIndicatorStatus, { progressStatus: indicator1, sequenceNumber: '1', label: 'Prepare Request', withWarning: (requestStatus === DisposalRequestStatus.New &&
                    multipleRetentionClasses) ||
                    withWarning == true }), jsx(DisposalRequestProgressIndicatorStatus, { progressStatus: indicator2, sequenceNumber: '2', label: 'Request Approval', withWarning: requestStatus === DisposalRequestStatus.InApproval ||
                    withWarning == true }), jsx(DisposalRequestProgressIndicatorStatus, { progressStatus: indicator3, sequenceNumber: '3', label: 'Action Disposal', withWarning: false, isBlocked: requestStatus === DisposalRequestStatus.Failed, isLast: true })] }));
};

export { DisposalRequestProgressIndicator };
