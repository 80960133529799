const ALERT_TEXTS = {
    approve: {
        failure: {
            title: 'Response submission failure',
            message: 'Your response submission failed. Please try again, or contact your Administrator.',
        },
        success: {
            title: 'Disposal Request Approved',
            message: 'Your response was successfully submitted.',
        }
    },
    reject: {
        failure: {
            title: 'Reject disposal request failure',
            message: 'Your attempt to reject the disposal request failed. Please try again, or contact your Administrator.',
        },
        success: {
            title: 'Disposal Approval Rejection',
            message: 'Your have successfully rejected the disposal approval.',
        }
    },
    delegate: {
        failure: {
            title: 'Delegate approval failure',
            message: 'Your attempt to delegate the approval failed. Please try again, or contact your Administrator.',
        },
        success: {
            title: 'Delegate Disposal Request',
            message: 'Your response was successfully submitted.',
        }
    },
    requestApproval: {
        failure: {
            title: 'Disposal Approval Request Failure',
            message: 'Your attempt to request approval failed. Please try again, or contact your Administrator.',
        },
        success: {
            title: 'Disposal Approval Requested',
            message: 'Disposal Request is now in Approval.',
        }
    },
};

export { ALERT_TEXTS };
