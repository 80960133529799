import { useState } from 'react';

const useApprovers = (initialApprovers) => {
    const [selectedApprovers, setSelectedApprovers] = useState(initialApprovers);
    const [isApproversListValid, setIsApproversListValid] = useState(true);
    return {
        selectedApprovers,
        setSelectedApprovers,
        isApproversListValid,
        setIsApproversListValid,
    };
};

export { useApprovers };
