import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Divider } from '@mui/material';
import { Box } from './Box.js';
import { HomeButton, ParentNavigationButton } from './button/CustomButton.js';

const ItemNavigationBar = ({ parentName = 'Disposal Request', onHome, onParent, }) => {
    const theme = useTheme();
    return (jsxs(Box, { background: 'dark', direction: 'row', rounded: 'top', height: 3.25, alignItems: 'center', justifyContent: 'start', gap: 'xsmall', style: { padding: '0 1rem' }, children: [jsx(HomeButton, { color: 'white', onClick: onHome && onHome, "data-testid": 'item-nav-bar-home-btn' }), jsx(Divider, { orientation: 'vertical', style: {
                    borderColor: theme.palette.info[800],
                    height: '1.25rem',
                    marginRight: '0.25rem',
                } }), onParent && (jsx(ParentNavigationButton, { size: 'small', color: 'white', onClick: onParent, text: parentName, "data-testid": 'item-nav-bar-parent-btn' }))] }));
};

export { ItemNavigationBar };
