import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { Note16Regular } from '@fluentui/react-icons';
import { useState, useEffect } from 'react';
import { DisposalRequestResponseStatus } from '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import { Box } from '../../Box.js';
import { CommentText } from '../../CommentText.js';
import '@mui/material';
import { TextIconButton } from '../../button/TextIconButton.js';

const ResponseCommentsTemplate = ({ responses, onClick, ...boxprops }) => {
    const [commentResponses, setCommentResponses] = useState();
    const [length, setLength] = useState(0);
    useEffect(() => {
        const _responses = responses?.filter((response) => [
            DisposalRequestResponseStatus.Approved,
            DisposalRequestResponseStatus.Rejected,
            DisposalRequestResponseStatus.Delegated,
        ].includes(response.Status) &&
            (response.ResponseComments?.length ?? 0) > 0);
        setCommentResponses(_responses);
        setLength(_responses?.length ?? 0);
    }, [responses]);
    return (jsxs(Box, { background: 'none', alignItems: 'start', justifyContent: 'start', style: {
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        }, ...boxprops, children: [length === 0 && jsx(Fragment, {}), length === 1 && (jsx(CommentText, { comment: responses[0]?.ResponseComments, variant: 'body2', numberOfLines: 5, onClick: onClick ? () => onClick(commentResponses) : undefined })), length > 1 && (jsx(TextIconButton, { color: 'info', hoverColor: 'primary', textVariant: 'body2', startIcon: jsx(Note16Regular, {}), onClick: onClick
                    ? (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick(commentResponses);
                    }
                    : undefined, text: `${length} Comments`, sx: { marginTop: '-0.5rem' } }))] }));
};

export { ResponseCommentsTemplate };
