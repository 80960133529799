import { jsx, Fragment } from 'react/jsx-runtime';
import { DismissSquare20Regular, Info20Regular } from '@fluentui/react-icons';
import { DisposalRequestStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { useTheme, Alert } from '@mui/material';

const getMessageForStatus = (request, multipleRetentionClasses) => {
    switch (request.Status) {
        case DisposalRequestStatus.Rejected:
            return 'Disposal of Items in this request have been rejected.';
        case DisposalRequestStatus.Approved:
            if (multipleRetentionClasses) {
                return 'Items in this request have disposal exceptions, because they are assigned to multiple retention classes and are partially triggered, approved or rejected. Remove or reclassify them before destroying other items in the request.';
            }
            return 'This Disposal Request has been fully approved and is ready to dispose.';
        case DisposalRequestStatus.New:
            if (multipleRetentionClasses) {
                return 'Items in this request have disposal exceptions, because they are assigned to multiple retention classes and are partially triggered, approved or rejected.';
            }
            break;
        case DisposalRequestStatus.Failed:
            return 'Items in this request have failed to be Destroyed. In the Items Tab, retry or manually destroy the repository information.';
        case DisposalRequestStatus.InProgress:
            return 'The destruction of items has been actioned.';
        case DisposalRequestStatus.Complete:
            return 'The destruction of items has completed.';
        default:
            return '';
    }
    return '';
};
const DisposalRequestStatusAlert = ({ request, multipleRetentionClasses, }) => {
    const theme = useTheme();
    let type = 'success';
    let color = theme.palette.warning.main;
    let background = theme.palette.warning[50];
    let icon = jsx(DismissSquare20Regular, { color: color });
    const message = getMessageForStatus(request, multipleRetentionClasses);
    if (request.Status === DisposalRequestStatus.Rejected) {
        type = 'warning';
        color = theme.palette.warning.main;
        background = theme.palette.warning[50];
        icon = jsx(DismissSquare20Regular, { color: color });
    }
    else if (request.Status === DisposalRequestStatus.Approved &&
        !multipleRetentionClasses) {
        type = 'success';
        color = theme.palette.primary.main;
        background = theme.palette.primary[50];
        icon = jsx(Info20Regular, {});
    }
    else if (request.Status === DisposalRequestStatus.Complete ||
        request.Status === DisposalRequestStatus.InProgress) {
        type = 'success';
        color = theme.palette.primary.main;
        background = theme.palette.primary[50];
        icon = jsx(Info20Regular, {});
    }
    else if (!message) {
        return jsx(Fragment, {});
    }
    return (jsx(Alert, { severity: type, icon: icon, sx: {
            borderRadius: 0,
            boxShadow: 'none',
            color: color,
            background: background,
            fontWeight: 600,
            lineHeight: '1.375rem'
        }, children: message }));
};

export { DisposalRequestStatusAlert, getMessageForStatus };
