import { useGetRequestDetailsQuery } from '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';

const useDisposalRequest = ({ id, userApiError = false }) => {
    const { data, isSuccess, isError, error, refetch } = useGetRequestDetailsQuery({
        requestId: id,
    }, { skip: !id });
    if (isError && error) {
        console.error(error);
    }
    const transformedData = userApiError ? undefined : data;
    return { data: transformedData, isSuccess, isError, refetch };
};

export { useDisposalRequest };
