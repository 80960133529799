import { useTheme } from '@mui/system';

function useStyles() {
    const theme = useTheme();
    const defaultStyles = {
        select: {
            borderRadius: 2,
            '&:focus': {
                borderColor: 'unset !important',
            },
            '&:focus-visible': {
                borderColor: 'unset !important',
            },
            '&>.MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.info[600]} !important`,
            },
            '&>.MuiSelect-select': {
                padding: '0.6rem !important',
            },
        },
        menuItem: {
            padddingTop: '0px !important',
            '&.Mui-selected': {
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                backgroundColor: `${theme.palette.info.light} !important`,
            },
            '&:hover': {
                backgroundColor: `${theme.palette.background.default} !important`,
            },
        },
    };
    return {
        styles: defaultStyles,
    };
}

export { useStyles as default };
