import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { Button } from '../button/Button.js';
import '@fluentui/react-icons';

const ConfirmDialog = ({ title, icon, open, message, actionButtonText, closeButtonText, onAction, onClose, }) => {
    useTheme();
    return (jsxs(Dialog, { open: !!open, PaperProps: {
            sx: {
                minWidth: '20rem',
                maxWidth: '30rem',
                minHeight: '4rem',
                maxHeight: '34rem',
            },
        }, children: [jsxs(DialogTitle, { children: [!!icon && icon, title] }), jsx(DialogContent, { children: jsx(Typography, { variant: 'body1', children: message }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', onClick: onAction, children: actionButtonText }), jsx(Button, { variant: 'outlined', onClick: onClose, children: closeButtonText })] })] }));
};

export { ConfirmDialog, ConfirmDialog as default };
