import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useSensors, useSensor, PointerSensor, DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useState, useEffect } from 'react';
import { Box } from './Box.js';
import { useTheme, List, TextField, ListItem, ListItemButton, Typography, Collapse, Tooltip } from '@mui/material';
import { ChevronUp20Regular, ChevronDown20Regular, ChevronUp16Regular, ChevronDown16Regular, People16Regular, Person16Filled } from '@fluentui/react-icons';
import { DisposalRequestResponseStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { Button } from './button/Button.js';
import { SortableItem } from './list/SortableItem.js';
import { useLazyGetApproversListWithParamsQuery } from '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import useOutsideClick from '../hooks/useOutsideClick.js';

const EditApprovers = ({ approvers, selectedApprovers, setSelectedApprovers, isApproversListValid, setIsApproversListValid, singleApprovalSelect = false, onSelect, isDisposalRequestRejected = false }) => {
    const theme = useTheme();
    const handleClickOutside = () => {
        setApproversSelectListHidden(true);
    };
    const defaultSelectLabel = '- Add an approver -';
    const [selectLabel, setSelectLabel] = useState(defaultSelectLabel);
    const ref = useOutsideClick(handleClickOutside);
    const [filteredLocalApprovers, setFilteredLocalApprovers] = useState(approvers.value);
    const [approversSearchQuery, setApproversSearchQuery] = useState('');
    const [approversSelectListHidden, setApproversSelectListHidden] = useState(true);
    const [actionedApprovers, setActionedApprovers] = useState([]);
    const [pendingApprovers, setPendingApprovers] = useState([]);
    const selectButtonBorderColor = isApproversListValid
        ? theme.palette.info[600]
        : theme.palette.warning.main;
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: { delay: 150, tolerance: 5 },
    }));
    const handleOnDelete = (index, approver) => {
        var array = [...selectedApprovers];
        if (index !== -1) {
            array.splice(index, 1);
            setSelectedApprovers(array);
            setFilteredLocalApprovers([...filteredLocalApprovers, approver]);
            if (array.length == 0) {
                setIsApproversListValid(false);
            }
        }
    };
    const [getApproversBySearch, { isSuccess: isSuccessSearch, data: dataSearch, isLoading: isLoadingSearch, },] = useLazyGetApproversListWithParamsQuery();
    useEffect(() => {
        if (approversSearchQuery === '') {
            const newApproversList = approvers.value.filter(function (_approver) {
                return !selectedApprovers.find(function (_selectedApprover) {
                    return _approver._Display === _selectedApprover._Display;
                });
            });
            setFilteredLocalApprovers(newApproversList);
        }
        else {
            getApproversBySearch({ searchFor: approversSearchQuery });
            if (!isLoadingSearch &&
                isSuccessSearch &&
                (dataSearch?.value?.length ?? 0) > 0) {
                if (selectedApprovers.length === 0) {
                    setFilteredLocalApprovers(dataSearch.value);
                }
                else {
                    const newApproversList = dataSearch.value.filter((_approver) => {
                        return ((_approver._Display
                            .toLowerCase()
                            .includes(approversSearchQuery.toLowerCase()) ||
                            _approver.Department?.toLowerCase().includes(approversSearchQuery.toLowerCase())) &&
                            !selectedApprovers.some((_obj) => _obj.ID === _approver.ID));
                    });
                    setFilteredLocalApprovers(newApproversList);
                }
            }
            else {
                setFilteredLocalApprovers([]);
            }
        }
    }, [approversSearchQuery]);
    useEffect(() => {
        if (selectedApprovers?.length > 0) {
            const tempActionedApprovers = [];
            const tempPendingApprovers = [];
            // Seperate actioned users from non-actioned users
            selectedApprovers.forEach((approver) => {
                if (!isDisposalRequestRejected && (approver.Status === DisposalRequestResponseStatus.Approved || approver.Status === DisposalRequestResponseStatus.Rejected || approver.Status === DisposalRequestResponseStatus.Delegated)) {
                    tempActionedApprovers.push(approver);
                }
                else
                    tempPendingApprovers.push(approver);
            });
            setActionedApprovers(tempActionedApprovers);
            setPendingApprovers(tempPendingApprovers);
        }
    }, [selectedApprovers]);
    const handleSort = (index, approver) => {
        var array = [...filteredLocalApprovers];
        if (index !== -1) {
            array.splice(index, 1);
            setFilteredLocalApprovers(array);
            setSelectedApprovers([...selectedApprovers, approver]);
            setIsApproversListValid(true);
        }
    };
    function handleDragEnd({ active, over }) {
        const newIndex = selectedApprovers.findIndex((item) => item.ID === over.id);
        const invalidSwap = !isDisposalRequestRejected && (selectedApprovers[newIndex]?.Status === 'Approved' || selectedApprovers[newIndex]?.Status === 'Rejected');
        if (active.id !== over.id && !invalidSwap) {
            setSelectedApprovers((selectedApprovers) => {
                const oldIndex = selectedApprovers.findIndex((item) => item.ID === active.id);
                return arrayMove(selectedApprovers, oldIndex, newIndex);
            });
        }
    }
    const ApproversHierarchicalList = ({ approversList, }) => {
        // Group items by department
        const groupedItems = approversList?.reduce((acc, item) => {
            let { Department } = item;
            if (Department === null || Department === '')
                Department = '*No department*';
            if (!acc[Department]) {
                acc[Department] = [];
            }
            acc[Department].push(item);
            return acc;
        }, {});
        const [open, setOpen] = useState({});
        const handleClick = (index) => {
            setOpen((prevOpen) => ({
                ...prevOpen,
                [index]: !prevOpen[index],
            }));
        };
        return (jsx(Fragment, { children: Object.keys(groupedItems).map((department) => (jsxs("div", { children: [jsx(ListItem, { disablePadding: true, onClick: () => handleClick(department), children: jsxs(ListItemButton, { children: [open[department] ? (jsx(ChevronUp16Regular, {})) : (jsx(ChevronDown16Regular, {})), jsx(People16Regular, { style: { marginLeft: '0.5rem' } }), jsx(Typography, { variant: 'body2', ml: 1, children: department })] }) }, `main-${department}`), jsx(Collapse, { in: !open[department], timeout: 'auto', unmountOnExit: true, children: groupedItems[department].map((approver, index) => (jsx(ListItem, { disablePadding: true, sx: { paddingLeft: '2.5rem' }, children: jsxs(ListItemButton, { onClick: () => {
                                    if (singleApprovalSelect) {
                                        setSelectLabel(approver._Display);
                                        onSelect(approver);
                                    }
                                    else {
                                        handleSort(index, approver);
                                    }
                                }, children: [jsx(Person16Filled, {}), jsx(Tooltip, { title: approver.Mail, placement: 'top', arrow: true, children: jsx(Typography, { variant: 'body2', ml: 1, children: approver._Display }) })] }) }, approver.ID))) })] }, department))) }));
    };
    return (jsxs(Box, { width: '100%', background: 'none', children: [actionedApprovers?.length > 0 && pendingApprovers?.length > 0 && (jsxs(Fragment, { children: [jsx(List, { dense: true, sx: {
                            width: '100%',
                            borderRadius: '0.5rem',
                            border: `1px solid blue`,
                            padding: 0,
                            overflow: 'auto',
                            maxHeight: '12rem',
                        }, children: actionedApprovers?.map((approver, index) => (jsx(SortableItem, { index: index, id: approver.ID, name: approver._Display, approver: approver, isDisposalRequestRejected: isDisposalRequestRejected }, `'nonsortable_' + ${approver.ID}`))) }), jsx("div", { style: {
                            width: '100%',
                            height: '1px',
                            backgroundColor: theme.palette.divider,
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        } })] })), pendingApprovers?.length > 0 ? (jsx(DndContext, { collisionDetection: closestCenter, onDragEnd: handleDragEnd, sensors: sensors, children: jsx(List, { dense: true, sx: {
                        width: '100%',
                        borderRadius: '0.5rem',
                        border: `1px solid ${theme.palette.background.paper}`,
                        padding: 0,
                        overflow: 'auto',
                        maxHeight: '12rem'
                    }, children: jsx(SortableContext, { items: pendingApprovers.map((item) => item.ID), children: pendingApprovers?.map((approver, index) => (jsx(SortableItem, { index: index + actionedApprovers?.length, id: approver.ID, name: approver._Display, approver: approver, onDelete: () => handleOnDelete(index + actionedApprovers?.length, approver), isDisposalRequestRejected: isDisposalRequestRejected, notDraggable: pendingApprovers?.length === 1 }, `'sortable_' + ${approver.ID}`))) }) }) })) : null, jsx("div", { ref: ref, style: { marginTop: '0.5rem' }, children: jsxs(Box, { background: 'none', children: [jsx(Button, { color: 'info', variant: 'outlined', endIcon: approversSelectListHidden ? (jsx(ChevronUp20Regular, { color: theme.palette.primary.main })) : (jsx(ChevronDown20Regular, { color: theme.palette.primary.main })), sx: {
                                fontFamily: 'Avenir Roman',
                                justifyContent: 'space-between',
                                borderColor: selectButtonBorderColor,
                                width: '100%',
                            }, onClick: () => {
                                setApproversSelectListHidden(!approversSelectListHidden);
                            }, children: selectLabel }), jsxs(Box, { background: 'none', style: {
                                width: 'calc(100% - 4rem)',
                                borderRadius: '0.5rem',
                                border: `0.063rem solid ${theme.palette.info[600]}`,
                                display: approversSelectListHidden ? 'none' : '',
                                position: 'absolute',
                                background: 'white',
                                marginTop: '2.813rem',
                                boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
                            }, children: [jsx(TextField, { size: 'small', sx: {
                                        margin: '0.5rem',
                                        padding: '5px !important',
                                    }, onChange: (event) => {
                                        setApproversSearchQuery(event.target.value);
                                    } }), jsx(List, { sx: {
                                        width: '100%',
                                        borderRadius: '0.5rem',
                                        padding: 0,
                                        overflow: 'scroll',
                                        maxHeight: '11rem',
                                    }, children: jsx(ApproversHierarchicalList, { approversList: filteredLocalApprovers }) })] })] }) })] }));
};

export { EditApprovers };
