import { useState } from 'react';

const useAlert = (initialOpen) => {
    const [isOpen, setIsOpen] = useState(initialOpen);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const openAlert = () => setIsOpen(true);
    const closeAlert = () => setIsOpen(false);
    return {
        isOpen,
        title,
        message,
        setTitle,
        setMessage,
        openAlert,
        closeAlert,
    };
};

export { useAlert };
