import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, Fragment } from 'react';
import { DisposalRequestResponseStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { formatDate } from '../util/dateTime.js';
import { Box } from './Box.js';
import { CommentText } from './CommentText.js';
import { DetailsRow } from './DetailsRow.js';
import { DisposalRequestResponseStatusChip } from './chip/DisposalRequestResponseStatusChip.js';
import { DetailDialog } from './dialog/DetailDialog.js';

const DisposalRequestResponses = ({ responses, }) => {
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [commentDialogText, setCommentDialogText] = useState('');
    const commentBoxOpenHandler = (text) => {
        setCommentDialogOpen(true);
        setCommentDialogText(text);
    };
    const infoDialogCloseHandler = () => {
        setCommentDialogOpen(false);
        setCommentDialogText('');
    };
    return (jsxs(Box, { background: 'none', style: {
            overflow: 'visible',
        }, children: [jsx(DetailDialog, { open: commentDialogOpen, title: 'Comment', onClose: infoDialogCloseHandler, children: commentDialogText }), responses?.map((approval, index) => {
                const key = `${approval?.ApproverId}-${index}`;
                if (approval.Status === DisposalRequestResponseStatus.Pending ||
                    approval.Status === DisposalRequestResponseStatus.Waiting)
                    return jsx(Fragment, {}, key);
                return (jsxs(Box, { background: 'none', style: { marginBottom: '2.813rem' }, children: [jsx(DetailsRow, { label: 'Decision', data: jsx(DisposalRequestResponseStatusChip, { status: approval.Status }), alignData: true }), jsx(DetailsRow, { label: 'Response By', data: approval.Approver?.DisplayName }), jsx(DetailsRow, { label: 'Date Responded', data: formatDate(approval?.DateResponded) }), jsx(DetailsRow, { label: 'Response Comments', data: jsx(Box, { background: 'none', children: jsx(CommentText, { comment: approval.ResponseComments, numberOfLines: 15, variant: 'body2', onClick: () => commentBoxOpenHandler(approval.ResponseComments) }) }) })] }, index));
            })] }));
};

export { DisposalRequestResponses };
