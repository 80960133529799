import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { Typography } from '@mui/material';
import { Box } from './Box.js';
import { Info20Regular, Warning20Regular, Dismiss20Regular } from '@fluentui/react-icons';

const BannerNotice = ({ text, onClose, type = 'info', className, isRelative = false, icon, }) => {
    const _className = `${isRelative ? 'ecs-banner-notice-relative' : 'ecs-banner-notice'} ecs-banner-notice-${type}` + (className ?? '');
    //can apply custom className
    return (jsx(Fragment, { children: jsxs(Box, { className: _className, direction: 'row', padding: 'small', justifyContent: 'space-between', alignItems: 'center', children: [!!icon && icon, type === 'info' && !icon && (jsx(Info20Regular, { className: 'ecs-banner-notice-icon' })), type !== 'info' && !icon && (jsx(Warning20Regular, { className: 'ecs-banner-notice-icon' })), jsx(Box, { background: 'none', children: jsx(Typography, { sx: { wordBreak: 'break-word', whiteSpace: 'normal' }, className: 'ecs-banner-notice-text', variant: 'h2', children: text }) }), !!onClose && (jsx(Dismiss20Regular, { className: 'ecs-banner-notice-icon ecs-banner-notice-dismiss', onClick: onClose }))] }) }));
};

export { BannerNotice };
